const punycode = require("punycode/")

export function getKeywords() {
    try {
        const domain = window.location.host;
        let keyword = punycode.toUnicode(domain.split('.')[0]);

        if (keyword == "정보") {
            keyword = "세상의 모든";
        }
        return keyword;
    } catch (error) {
        console.log(error);
        return "";
    }
}