import { BrowserRouter } from 'react-router-dom';
import './App.css';
import CardContainer from './components/CardContainer';
import Header from './components/Header'
import Navigator from './components/Navigator';

const App = () => {

  return (
    <BrowserRouter>
      <div>
        <Header />
        <Navigator />
        <CardContainer />
      </div>
    </BrowserRouter>
  );
}

export default App;
