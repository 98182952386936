import React, { useEffect, useState } from 'react';
import { getKeywords } from '../utils/Parser';
import ReactDOMServer from 'react-dom/server';


const CardContainer = () => {
  const [cards, setCards] = useState([]);
  const [contents, setContents] = useState([]);
  let products = [];

  useEffect(() => {
    const keyword = getKeywords();
    if (keyword == "세상의 모든") {
      fetchData();
    } else {
      fetchKeywordData(keyword);
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('/api/cardData');
      const data = await response.json();
      setCards(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchKeywordData = async (keyword) => {
    try {
      const response = await fetch(`/api/info/${keyword}`);
      const data = await response.json();
      setContents(data);
    } catch (error) {
      console.error('키워드 데이터를 불러오는 중 에러 발생:', error);
    }
  };

  const handleCardClick = (keyword) => {
    const newUrl = `https://${keyword}.정보.net`;
    window.location.href = newUrl;
  };

  const CardData = () => <>
    {cards.map((card) => (
      <div className="container flex flex-col mx-auto mt-4 place-items-center justify-center h-full"  style={{ margin: "0 auto;" }}>
        <div key={card.no} onClick={() => handleCardClick(card.keywordname)}
          className="card">
          <div dangerouslySetInnerHTML={{ __html: card.content }} />
          <div className="card-content">
            <h1 className="text-4xl">{`${card.keywordname} 정보`}</h1>
            <br />
            <p className='justify-between' style={{ padding: '0 10px 0 10px', fontSize: '16px', textAlign: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: card.description }} />
            </p>
          </div>
        </div>
      </div>
      ))}
  </>;

  const coupangPartnersNoti = (
    <span style={{ fontSize: '10px', color: '#333'}}>
      이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.
    </span>
  );

  const customBox = (color, description) => (
    <div className={`message flex flex-col ${color}`} style={{ margin: '15px 0 30px 0' }}>
      <div style={{ margin: '0 auto', maxWidth: '800px' }}>
        <p className="flex flex-col text-18px font-medium mx-auto justify-between" style={containerImgStyle}
          dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );

  const containerStyle = {
    margin: '0 auto',
    paddingLeft: "12px",
    paddingRight: "12px",
    width: '100%',
    maxWidth: '1200px',
    overflowY: 'auto',
  };

  const containerImgStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  const renderContent = (arr) => {
    const no = arr.typeno;
    if (no == 2) {
      return renderHeadDescription(arr);
    } else if (no == 3) {
      return renderMiddleInfo(arr);
    } else if (no == 4) {
      const newProduct = productLinkBox(arr, products.length);
      products.push(newProduct);
      return null;
    } else return null;
  }

  const productLinkBox = (arr, index) => <div key={index} className="container flex flex-col mx-auto mt-4 place-items-center justify-center h-full" style={{ margin: "0 auto;" }}>
    <div className="productCard">
      <a href={`${arr.extra2}`} target="_blank">
        <div dangerouslySetInnerHTML={{ __html: arr.extra1 }} />
      </a>
      <div className="card-content">
        <p className="title text-13px" style={{color: "#999"}}>사진을 클릭하면 구매 페이지로 넘어갑니다.</p>
        <p className="title text-3xl">{arr.content}</p>
        <br />
        <p className="description text-16px" dangerouslySetInnerHTML={{ __html: arr.description }} />
      </div>
    </div>
  </div>;

  // renderHeadDescription 함수
  const renderHeadDescription = (arr) => {
    return customBox(arr.color, arr.description);
  }

  // renderMiddleInfo 함수
  const renderMiddleInfo = (arr) => {
    return customBox(arr.color, arr.description);
  }

  if (getKeywords() == "세상의 모든") {
    return (
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-auto place-items-center justify-center' style={{ margin: '0 auto', maxWidth: "1200px", }}>
        <CardData />
      </div>);
  } else {
    return (
      <div id="container" style={containerStyle}>
        {contents.map((content) => (
          <>
            {renderContent(content)}
          </>
        ))}
        <div className='text-2xl md:text-3xl text-center'>관련된 상품은 어떤 것들이 있나요?</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-auto place-items-center justify-center">
          {products}
        </div>
        <div className='text-center' style={{marginTop: "30px"}}>{coupangPartnersNoti}</div>
      </div>
    );
  }
};

export default CardContainer;