import React from 'react';
import { getKeywords } from '../utils/Parser';

const Navigator = () => {
    const backgroundImageUrl = 'https://hae.so/info/img/2023/11/12/4b06fa17-3523-455a-b54c-9df4d0eefc05';

    const navigatorStyle = {
        height: '280px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#2d2d2d',
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 1,
        lineHeight: "13px",
        justifyContent: 'center',
        alignItems: 'center',
    };

    const textStyle = {
        fontFamily: 'Pretendard-Regular',
        color: 'white',
        margin: 0,
        textShadow: "2px 2px #555",
    };

    return (
        <div style={navigatorStyle}>
            <p className="items-center text-center text-7xl flex flex-col" style={textStyle}>
                {getKeywords()} 정보</p><br/>
            <p className="items-center text-center text-16px" style={textStyle}>
                세상의 모든 정보 - 정보.net
             </p>
            
        </div>
    );
};

export default Navigator;