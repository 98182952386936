import React from 'react';
import { getKeywords } from '../utils/Parser';
import { Helmet } from 'react-helmet-async';

const Header = () => {
  const keyWord = getKeywords();
  const customStyles = `
  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  * {
      font-family: 'Pretendard-Regular';
  }
  .message {
      padding: 15px;
      margin: 1rem 0;
      border-radius: 18px;
  }
  #container img {
    margin:15px auto;
  }
  p {
      line-height:28px;
  }`;
  return (
    <div>
      <Helmet>
        <title>{keyWord} 정보 - 정보넷</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={`${keyWord} 정보에 대한 트렌드, 영상, 블로그, 구매 정보 등을 알려드립니다.`} />
        <meta name="keywords" content={`${keyWord}, ${keyWord} 정보, ${keyWord} 트렌드`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${keyWord} 정보 - 정보넷`} />
        <meta property="og:description" content={`${keyWord} 정보에 대한 트렌드, 영상, 블로그, 구매 정보 등을 알려드립니다.`} />
        <style>{customStyles}</style>
      </Helmet>
    </div>

  );
}

export default Header;